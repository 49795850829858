.coverflow-container {
    position: relative;
    width: 100%;
    padding: 0 0 5rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #05051e;
    margin-top: -1px;
  }
  
  .nav-button {
    border: none;
    font-size: 3rem;
    font-weight: 600;
    background: rgba(49, 95, 122, 0.44); /* Improved transparency */
    color: #4569d4;
    cursor: pointer;
    position: absolute;
    z-index: 10;
    width: 4rem;
    height: 4.4rem;
    border: 1px solid #5c5a5a;
    border-radius: 1rem;
    transition: background 0.3s ease, transform 0.3s ease; /* Added hover effect */
  
    &:hover {
      background: rgba(49, 95, 122, 0.7); /* Darker on hover */
      transform: scale(1.05); /* Slight scale-up effect */
    }
  
    &.left {
      left: 8rem;
    }
  
    &.right {
      right: 8rem;
    }
  }
  
  .coverflow-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    perspective: 1000px;
    width: 100%;
    height: 300px;
  }
  
  .coverflow-card {
    position: absolute;
    width: 400px;
    height: 100%;
    background-color: rgba(85, 106, 138, 0.54); /* Improved transparency */
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 0 14px rgba(228, 217, 217, 0.8); /* Improved shadow */
    transition: transform 0.5s ease, opacity 0.5s ease, box-shadow 0.5s ease; /* Added box-shadow transition */
    transform-origin: center;
    z-index: 0;
    opacity: 0;
  
    h2 {
      font-size: 5.2rem;
      margin-bottom: 10px;
      background: linear-gradient(135deg, #955595, #399580);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  
    p {
        font-size: 1rem;
        font-weight: 700; /* Slightly bolder for better visibility */
        margin: 0 15px;
        line-height: 1.6;
        color: #eff0f4;
        font-family: 'Big Shoulders Stencil', sans-serif;
        letter-spacing: 0.05em; /* Increased letter spacing for stencil font */
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Stronger shadow for depth */
      }
  
    &.activeSlide {
      transform: translateX(0) scale(1);
      opacity: 1;
      z-index: 5;
      box-shadow: 0 0 20px rgba(228, 217, 217, 1); /* Enhanced shadow for active slide */
    }
  
    &.lastSlide {
      transform: translateX(-100%) scale(0.8) rotateY(45deg);
      opacity: 1;
      z-index: 1;
      width: 370px;
      box-shadow: 0 8px 27px rgba(255, 255, 255, 0.4); /* Improved shadow */
    }
  
    &.nextSlide {
      transform: translateX(100%) scale(0.8) rotateY(-45deg);
      opacity: 0.66;
      z-index: 1;
      width: 370px;
      box-shadow: 0 8px 27px rgba(71, 66, 66, 0.48); /* Improved shadow */
    }
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 1024px) {
    .nav-button {
      font-size: 2.5rem;
      width: 3rem;
      height: 3rem;
  
      &.left {
        left: 1rem;
      }
  
      &.right {
        right: 1rem;
      }
    }
  
    .coverflow-card {
      h2 {
        font-size: 3rem;
      }
  
      p {
        font-size: 0.9rem;
      }
    }
  }
  
  @media (max-width: 768px) {
    .coverflow-wrapper {
      height: 250px;
    }
  
    .coverflow-container {
      padding: 0 0 2rem 0;
    }
  
    .coverflow-card {
      width: 65%;
      padding: 0 1rem;
  
      h2 {
        font-size: 2.5rem;
      }
  
      p {
        font-size: 0.8rem;
      }
    }
  
    .nav-button {
      font-size: 2rem;
      width: 2.5rem;
      height: 2.5rem;
    }
  }
  
  @media (max-width: 480px) {
    .coverflow-wrapper {
      height: 200px;
    }
  
    .coverflow-card {
      width: 78%;
  
      h2 {
        font-size: 2rem; /* Adjusted for smaller screens */
      }
  
      p {
        font-size: 0.8rem;
        margin: 0;
      }
    }
  
    .coverflow-container {
      padding: 0 0 4rem 0;
    }
  
    .nav-button {
      font-size: 1.5rem;
      width: 2rem;
      height: 2rem;
    }
  }