/* Main Navbar Container */
.navbar-main-Container {
  background-color: #05051e;
  border-bottom: 0.5px solid rgba(83, 81, 81, 0.4);
  padding: 0 2rem;
  width: 100%;
  height: 10%;
  font-size: 1rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Navbar Content */
.navbar-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 3rem;
  height: 100%;
}

/* Navbar Logo */
.navbar-logo {
  display: flex;
  align-items: center;
  margin-right: auto;
  gap: 10rem;
}

/* Navbar Navigation */
.navbar-nav {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.8rem;
  height: 100%;
}

/* Nav Item */
.nav-item {
  position: relative;
}

/* Nav Link and Dropdown Button */
.nav-link,
.dropbtn {
  display: flex;
  align-items: center;
  color: #fdfeff;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  height: 100%;
  line-height: 1.4rem;
  padding: 0 1rem;
  transition: all 0.3s ease-in-out;
}

/* Hover Effect for Nav Link and Dropdown Button */
.nav-link:hover,
.dropbtn:hover {
  background: linear-gradient(135deg, #955595, #399580);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

/* Full-Screen Dropdown */
.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 200px;
  background-color: #0c0c2f;
  border-radius: 0.5rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s;
  border: 1px solid rgba(149, 85, 149, 0.2);
}

/* Open State for Dropdown */
.dropdown-content.open {
  display: block;
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

/* Dropdown Links */
.dropdown-link {
  display: block;
  padding: 0.8rem 1.5rem;
  color: #fdfeff;
  text-decoration: none;
  font-size: 0.95rem;
  transition: all 0.3s ease;
  border-bottom: 1px solid rgba(149, 85, 149, 0.1);

  &:last-child {
    border-bottom: none;
  }
}

/* Hover Effect for Dropdown Links */
.dropdown-link:hover {
  background: linear-gradient(135deg, rgba(149, 85, 149, 0.1), rgba(57, 149, 128, 0.1));
  color: #fff;
  padding-left: 2rem;
}

/* Show Dropdown on Hover */
.nav-item.dropdown:hover .dropdown-content {
  display: block;
}

/* Navbar Options */
.navbar-options {
  display: flex;
  justify-content: end;
  align-items: center;
  padding-left: 250px;
  min-width: fit-content;
}

/* Navbar Account Section */
.navbar-account-section {
  display: flex;
  gap: 0.79rem;
  align-items: center;
  flex-wrap: nowrap;
}

/* Navbar Buttons */
.navbar-button {
  padding: 0.6rem 0.8rem;
  border-radius: 0.5rem;
  cursor: pointer;
  color: #f1f2f8;
  font-size: 1rem;
  font-weight: 500;
  white-space: nowrap;
  min-width: fit-content;
}

/* Navbar Signup Button */
.navbar-signup-button {
  background: linear-gradient(135deg, #955595, #399580);
  color: white;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
  padding: 0.6rem 1.2rem;
  font-size: 0.95rem;
}

/* Hover Effect for Signup Button */
.navbar-signup-button:hover {
  background: linear-gradient(135deg, #399580, #955595);
  color: #fff;
  border: 1px solid white;
}

/* Navbar Login Button */
.navbar-login-button {
  background-color: #0c0e13;
  border: 0.4px solid rgba(180, 177, 177, 0.44);
  color: white;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  padding: 0.6rem 1.2rem;
  font-size: 0.95rem;
}

/* Hover Effect for Login Button */
.navbar-login-button:hover {
  background-color: rgb(137, 210, 239);
  color: #0c0e13;
  border-color: rgb(137, 209, 238);
}

/* Slide-in Mobile Menu */
.mobile-slide-menu {
  position: fixed;
  top: 0;
  left: -100vw;
  width: 100vw;
  height: 100vh;
  background-color: #060620;
  // box-shadow: 1px 2px 5px rgb(238, 233, 233);
  z-index: 1001;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  transition: left 0.4s ease-in-out;

  &.slide-in {
    left: 0;
  }

  &.slide-out {
    left: -100vw;
  }

  /* Close Button (Top Right) */
  .close-menu-button {
    position: absolute;
       top: 10px;
    right: 2.4rem;
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      transform: rotate(180deg);
    }
  }

  .mobile-logo {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 120px; 
    height: 65px;
    display: flex;
    align-items: center; 
    justify-content: center;
}




  /* Mobile Nav Items (Only visible in slide menu) */
  .mobile-nav-item {
    margin: 1rem 0;
    align-items: center;
    

    .nav-link {
  font-size: 1.8rem;
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  display: flex; 
  align-items: center; 
  justify-content: center; 
  text-align: center; 
  width: 100%; 
  height: 100%; 


      &:hover {
        color: #1b7fff;
      }
    }
  }

  .mobile-account-section {
    margin-top: 2rem;
    text-align: center;

    .mobile-signup-button,
    .mobile-login-button {
      padding: 0.5rem 1.5rem;
      border: none;
      color: #fff;
      font-size: 1.2rem;
      cursor: pointer;
    }

    .mobile-signup-button {
      background-color: #955595;
      margin-bottom: 1rem;
      padding: 0.5rem 1.4rem;
      border: none;
      border-radius: 8px;
      color: white;
      font-size: 1rem;
      font-weight: 600;
      cursor: pointer;
      transition: background-color 0.3s ease, transform 0.2s ease;

      &:hover {
        background-color: #210d21;
        transform: scale(1.05);
      }

      &:active {
        transform: scale(0.95);
      }
    }

    .mobile-login-button {
      background-color: #1b7fff;
      padding: 0.5rem 1.4rem;
      border: rgb(0, 0, 0);
      border-radius: 8px;
      color: white;
      font-size: 1rem;
      margin: 10px;
      font-weight: 600;
      cursor: pointer;
      transition: background-color 0.3s ease, transform 0.2s ease;

      &:hover {
        background-color: #2b2d2f;
        transform: scale(1.05);
      }

      &:active {
        transform: scale(0.95);
      }
    }
  }
}

/* Mobile View Adjustments */
@media screen and (max-width: 1024px) {
  .navbar-main-Container {
    padding: 0 0.5rem;
  }

  .navbar-logo {
    gap: 3rem;
  }

  .navbar-content-for-mobile-view div {
    background-color: rgb(255, 255, 255);
    width: 2rem;
    border-radius: 1rem;
    margin: 0.3rem;
    height: 0.2rem;
  }

  .navbar-nav {
    display: none;
  }

  .navbar-options {
    display: none;
  }

  .navbar-button {
    display: none;
  }
  

  

 
}


 
@media screen and (max-width: 768px) {
  .navbar-main-Container {
    padding: 0 0.5rem;
  }

  .navbar-logo {
    gap: 3rem;
  }

  .navbar-content-for-mobile-view div {
    background-color: rgb(255, 255, 255);
    width: 2rem;
    border-radius: 1rem;
    margin: 0.3rem;
    height: 0.2rem;
  }

  .navbar-nav {
    display: none;
  }

  .navbar-options {
    display: none;
  }

  .navbar-button {
    display: none;
  }


  // .navbar-login-button{
  //   display: block;
  // }
}


@media screen and (max-width: 600px) {
  .navbar-main-Container {
    padding: 0 0.2rem;
  }

  .navbar-content-for-mobile-view {
    display: block;
    height: auto;
    width: auto;
    margin-right: 1.6rem;

    div {
      background-color: #fff;
      border-radius: 1rem;
      height: .2rem;
      margin: .3rem;
      transition: .5s ease-in-out;
      width: 2rem;
    }
  }
}

@media (min-width: 100px) and (max-width: 560px) {
  .navbar-main-Container {
    padding: 0.2rem;
    font-size: 0.8rem;
    height: 10%;

    .navbar-content {
      justify-content: space-between;
      position: relative;

      .navbar-logo {
        transform: scale(1.8);
        margin-left: 1rem;
      }

      .navbar-nav {
        flex-direction: column;
        gap: 1rem;
        text-align: center;
        font-size: 2.5rem;
        margin-top: 2.5rem;
        height: 30%;
        display: none;

        .nav-item {
          .nav-link {
            color: white;
            height: 100%;
            text-decoration: none;
          }

          .nav-link:hover {
            border-bottom: 4px solid #1b7fff;
          }
        }
      }

      .navbar-options-for-mobile-view {
        position: absolute;
        justify-content: initial;
        gap: 3rem;
        width: 100vw;
        top: 0px;
        overflow-y: hidden;
        right: 0px;
        height: 100vh;
        background: #ffffff;
        flex-direction: column;
      }

      .navbar-content-for-mobile-view {
        display: flex;
        flex-direction: column;
        margin-right: 1rem;
        cursor: pointer;

        div {
          background-color: rgb(255, 255, 255);
          width: 2rem;
          border-radius: 1rem;
          margin: 0.3rem;
          height: 0.2rem;
        }
      }

      .after-click {
        div:nth-child(1) {
          position: relative;
          transform: rotate(45deg);
          top: 0.31rem;
          left: 0rem;
          transition: .5s ease-in-out;
        }

        div:nth-child(2) {
          opacity: 0;
        }

        div:nth-child(3) {
          position: relative;
          transform: rotate(-45deg);
          top: -21px;
          transition: .5s ease-in-out;
          left: 0px;
        }
      }
    }

    .navbar-options {
      display: none;

      .navbar-account-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;

        .button {
          padding: 0.625rem 1.8rem;
          border-radius: 0.5rem;
          font-weight: 700;
          border: none;
          font-size: 1.2rem;
          color: #fff;
          cursor: pointer;
        }

        .navbar-signup-button {
          background-color: #1c1c1c;
        }

        .navbar-login-button {
          background-color: #1b7fff;
        }
      }
    }
  }
}