.homeContainer {
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  background-color: #05051e;
  overflow: hidden;
  color: white;

  .centerContainer {
    margin: 0 auto;
    width: 70%;
    display: flex;
    justify-content: space-between;
    height: 100%;

    .leftContent {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      /* padding: 0 20px; */
      text-align: left;

      h1 {
        text-align: center;
        font-size: 3.4rem;
        font-weight: 700;
        text-transform: uppercase;
        margin-top: 1rem;
        line-height: 4rem;
        color: #c2c7de;
        color: #ffffff;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add shadow */

        span {
          background: linear-gradient(135deg, #955595, #399580);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
        }
      }

      h4 {
        text-align: center;
        font-size: 1.6rem;
        font-weight: 700;
        text-transform: capitalize;
        color: #e8e8eb;
        line-height: 2rem;
        margin-top: 1rem;
        padding: 0 7rem;
        transition: color 0.3s ease;
      }
      
      h4:hover {
        color: #a9a3a3; /* Change color on hover */
      }

      .Hero-btnWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2rem;

        // .heroBtn1 {
        //   margin-top: 2rem;
        //   color: #a3a8bf;
        //   font-size: 1.1rem;
        //   text-transform: uppercase;
        //   line-height: 1.2rem;
        //   font-weight: 500;
        //   padding: 1.1rem 3.2rem;
        //   border-radius: 0.11rem;
        //   cursor: pointer;
        //   //  background: linear-gradient(135deg, #955595, #399580);

        //   border: 2px solid;
        //   border-image: linear-gradient(135deg, #955595, #399580) 1;
        // }

        // .heroBtn2 {
        //   margin-top: 2rem;
        //   color: #a3a8bf;
        //   font-size: 1.1rem;
        //   text-transform: uppercase;
        //   line-height: 1.2rem;
        //   font-weight: 400;
        //   padding: 1.1rem 3.2rem;
        //   border-radius: 0.11rem;
        //   cursor: pointer;
        //   background: linear-gradient(135deg, #955595, #399580);
        // }


        .heroBtn1 {
          margin-top: 2rem;
          color: #a3a8bf;
          font-size: 1.1rem;
          text-transform: uppercase;
          line-height: 1.2rem;
          font-weight: 500;
          padding: 1.1rem 3.2rem;
          border-radius: 0.11rem;
          cursor: pointer;
          border: 2px solid;
          border-image: linear-gradient(135deg, #955595, #399580) 1;
          position: relative;
          overflow: hidden;
          transition: all 0.3s ease;
        }
        
        .heroBtn1::before {
          content: "";
          position: absolute;
          top: 0;
          left: -100%;
          width: 100%;
          height: 100%;
          background: linear-gradient(135deg, #955595, #399580);
          transition: all 0.4s ease;
          z-index: -1;
        }
        
        .heroBtn1:hover {
          color: #fff;
          border-color: transparent;
        }
        
        .heroBtn1:hover::before {
          left: 0;
        }
        
        .heroBtn1:active {
          transform: scale(0.95);
        }
        
        .heroBtn2 {
          margin-top: 2rem;
          color: #a3a8bf;
          font-size: 1.1rem;
          text-transform: uppercase;
          line-height: 1.2rem;
          font-weight: 400;
          padding: 1.1rem 3.2rem;
          border-radius: 0.11rem;
          cursor: pointer;
          background: linear-gradient(135deg, #955595, #399580);
          position: relative;
          overflow: hidden;
          transition: all 0.3s ease;
        }
        
        .heroBtn2::before {
          content: "";
          position: absolute;
          top: 0;
          left: -100%;
          width: 100%;
          height: 100%;
          background: linear-gradient(135deg, #399580, #955595);
          transition: all 0.4s ease;
          z-index: -1;
        }
        
        .heroBtn2:hover {
          color: #fff;
          background: transparent;
          border: 2px solid transparent;
        }
        
        .heroBtn2:hover::before {
          left: 0;
        }
        
        .heroBtn2:active {
          transform: scale(0.95);
        }
      }

      ul {
        list-style: none;
      }
    }

    .rightContent {
      margin-top: 1rem;
      width: 46%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        mix-blend-mode: difference;
        object-position: center;
      }
    }
  }
}


// Respnsive Styling

@media screen and (min-width: 993px) {
  .homeContainer {
    height: 82vh;
  }

  .homeContainer .centerContainer {
    width: 80%;
  }
}

@media screen and (max-width: 993px) {
  .homeContainer {
    height: 82vh;
  }

  .homeContainer .centerContainer {
    width: 80%;
  }

  .homeContainer .centerContainer .leftContent h1 {
    font-size: 3rem;
  }

  .homeContainer .centerContainer .leftContent h4 {
    font-size: 1.4rem;
    padding: 0;
  }

  .homeContainer .centerContainer .leftContent .Hero-btnWrapper .heroBtn1,
  .homeContainer .centerContainer .leftContent .Hero-btnWrapper .heroBtn2 {
    font-size: 0.88rem;
    padding: 1.1rem 2rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .homeContainer {
    height: 55vh;
  }

  .navbar-main-Container .navbar-options {
    padding: 0rem 1rem;
  }
}

@media screen and (min-width: 820px) and (max-width: 900px) {
  .homeContainer .centerContainer {
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  .homeContainer .centerContainer {
    width: 84%;
  }
}

@media screen and (max-width: 660px) {
  .homeContainer {
    height: 82vh;
  }
}

@media only screen and (max-width: 560px) {
  .homeContainer {
    overflow-y: auto;

    .centerContainer {
      width: 90%;
      margin: 0 auto;
      flex-direction: column;

      .leftContent {
        margin-top: 3.2rem;
        text-align: center;
        width: 100%;

        h1 {
          font-size: 2.4rem;
          line-height: 3.5rem;
          margin-top: 0;
          text-align: left;
        }

        h4 {
          margin-top: 1rem;
          margin-left: 0rem;
          font-size: 1.3rem;
          font-weight: 400;
          text-align: left;
          margin-bottom: 2rem;
        }

        .Hero-btnWrapper {
          display: flex;
          justify-content: start;
          align-items: start;
          flex-direction: column;
          gap: 1rem;
          margin-bottom: 1rem;

          a {
            width: 100%;

            .heroBtn1,
            .heroBtn2 {
              font-size: 1rem;
              padding: 1.1rem 1rem;
              margin-top: 0;
              width: 70%;
              float: left;
              text-align: left;
            }

          }

        }

        .heroBtn1,
        .heroBtn2 {
          font-size: 0.8rem;
          padding: 0.94rem 1.4rem;
          display: flex;
          justify-content: start;
          align-items: start;
          gap: 0;
        }

      }
    }

    .rightContent {
      display: none;
      transform: scale(2);
    }
  }
}

@media only screen and (max-width: 375px) {
  .homeContainer {
    /* height: 92vh; */
    min-height: 88vh;
    overflow-y: hidden;
  }
}