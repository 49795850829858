.footer-container {
  padding: 2rem;
  text-align: center;
  background-color: #05051e;
  color: rgb(223, 219, 219);

  .footer-logo {
    display: grid;
    place-items: center;
    font-size: 1rem;
    // margin-right: 26px;
  }

  .footer-links-section {
    margin: 2rem;
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-left: 30px;

    .footer-links {
      font-size: 1.2rem;
      color: #e6e9f3;
      text-decoration: none;
      position: relative;
      transition: color 0.3s ease-in-out;
      margin-left: 15px;

      /* Hover Effect for Footer Links */
      &::after {
        content: "";
        display: block;
        width: 0;
        height: 2px;
        background: #ffffff;
        transition: width 0.3s ease-in-out;
        position: absolute;
        left: 0;
        bottom: -5px;
      }

      &:hover {
        color: #ffffff;

        &::after {
          width: 100%;
        }
      }
    }
  }

  /* Social Icons */
  .social-icons {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 15px;
    margin-bottom: 20px;
    margin-left: 25px;


    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px; /* Increased size for better touch targets */
      height: 30px;
      border-radius: 30%;
      background-color: #ffffff;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
      transition: all 0.3s ease-in-out;
      text-decoration: none;

      /* Hover Effect */
      &:hover {
        transform: scale(1.2);
        box-shadow: 0px 6px 15px rgba(255, 255, 255, 0.3);
      }

      /* Material-UI Icon Styling */
      .MuiSvgIcon-root {
        font-size: 20px;
        transition: color 0.3s ease-in-out;
      }

      /* Default Icon Colors */
      &.facebook-icon {
        color: #1877F2;
      }

      &.twitter-icon {
        color: #1DA1F2;
      }

      &.instagram-icon {
        color: #E4405F;
      }

      &.linkedin-icon {
        color: #0077B5;
      }

      &.youtube-icon {
        color: #FF0000;
      }

      /* Social Icons Hover Colors */
      &:hover.facebook-icon {
        background: #1877F2;
        color: #ffffff;
      }

      &:hover.twitter-icon {
        background: #1DA1F2;
        color: #ffffff;
      }

      &:hover.instagram-icon {
        background: #E4405F;
        color: #ffffff;
      }

      &:hover.linkedin-icon {
        background: #0077B5;
        color: #ffffff;
      }

      &:hover.youtube-icon {
        background: #FF0000;
        color: #ffffff;
      }
    }
  }

  /* Responsive Design */
  @media (max-width: 1024px) {
    padding: 1.5rem;

    .footer-links-section {
      gap: 1.5rem;
      // margin-left: 5rem; 
    }

    .social-icons {
      gap: 12px;
    }
  }

  @media (max-width: 768px) {
    padding: 1.2rem;

    .logo {
      margin-right: 1.5rem;
    }

    .footer-links-section {
      flex-wrap: wrap; 
      gap: 1rem;
      justify-content: center;
     align-items: center;
    }

    .social-icons {
      gap: 10px;
      margin-right: 1rem ;
      margin-top: 2rem;
    }
  }

  @media (max-width: 560px) {
    padding: 1rem;

    .footer-links-section {
      flex-direction: column; 
      gap: 0.8rem;
      margin-bottom: 1rem;
    }

    .social-icons {
      gap: 8px;
    }
  }

  @media (max-width: 360px) {
    padding: 0.8rem;
    

    .footer-links-section {
      text-align: center;
       
    }

    .social-icons {
      gap: 6px;
    }
  }
}