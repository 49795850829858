$bg-color: #F6F8FC;
$primary-text-color: #212943;
$secondary-text-color: #9EA6AE;
$details: #DEE4F1;

body {
    font-size: 10px;
    background: $bg-color;
}


.faq-container>.heading {
    color: $primary-text-color;
    font-size: 3.2rem;
    font-weight: 600;
    margin-bottom: 2rem;

    background: linear-gradient(180deg, #c231c2, #399580);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

    text-transform: capitalize;
}

.faq-container {
    margin: 0 auto;
    padding: 2rem;
    text-align: center;
    background: #05051E;
    color: #A3A8BF;
    margin-top: -1px;
}

form {
    display: flex;
    width: 35rem;
    background-color: #fff;
    align-items: center;
    margin: 0 auto;
    border-radius: 1rem;

    svg {
        height: 1rem;
        fill: $secondary-text-color;
        position: fixed;
        width: 4rem;
    }
}

.faq {
    margin: 3rem 0;
}

.question-wrapper {
    width: 90%;
    border-bottom: 1px solid #DEE4F1;
    margin: 0 auto;
    padding: 1.52rem;
    padding-left: 0;
    transition: 1s;
}

.question {
    display: flex;
    font-size: 1.4rem;
    font-weight: 500;
    color: $primary-text-color;
    display: flex;
    justify-content: space-between;
    color: #A3A8BF;

    h3 {
        text-align: left;
    }

    svg {
        width: 1rem;
        height: 1.5rem;
        fill: $secondary-text-color;
    }

    svg.active {
        transform: rotate(180deg);
    }

    svg:hover {
        opacity: 0.8;
    }
}

button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

button:focus {
    outline: none;
}

.answer {
    display: none;
}

.answer.active {
    display: block;
    text-align: left;
    padding-top: 1.5rem;
    font-weight: light;
    font-size: 1.2rem;
    line-height: 1.5;
    color: $secondary-text-color;
    // height: 0%;
    height: auto;
    animation: slidein 0.4s;
    animation-fill-mode: forwards;
}

@keyframes slidein {
    from {
        opacity: 0.3;
        transform: translateY(-20%)
    }

    to {
        opacity: 1;
        transform: translateY(0%);
    }
}

@media screen and (max-width: 900px) {
    .faq-container {
        padding: 1rem 2rem;

        .heading {
            margin: 0;
        }
    }

    .faq {
        margin: 1rem 0;
    }
}

@media screen and (max-width: 768px) {
    .faq-container {
        padding: 0rem;
    }

    .faq {
        margin: 0rem;
        padding-bottom: 1.4rem;
    }
}

@media screen and (max-width: 500px) {
    .faq-container {
        padding: 0.6rem 1rem;

        .heading {
            font-size: 2.4rem;
            margin-bottom: 0;
            text-align: left;
            padding: 0 12px;
        }

        .faq {
            margin: 0;

            .question-wrapper {
                width: 94%;

                .question {
                    font-size: 1.24rem;
                }

                .answer.active {
                    font-size: 1.1rem;
                }
            }
        }
    }
}