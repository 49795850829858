// Variables
$primary-color: #5a4fcf;
$secondary-color: #1f4b2e;
$light-bg: #f8f9fa;
$dark-text: #2d3748;
$light-text: #4a5568;
$white: #ffffff;
$box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
$border-radius: 12px;

.contact-page {
  font-family: 'Inter', sans-serif;
  color: $dark-text;
  line-height: 1.6;
  padding-bottom: 4rem;
}

.header_section {
  background: linear-gradient(135deg, rgba($primary-color, 0.9), rgba($secondary-color, 0.9)),
  url('https://images.unsplash.com/photo-1579389083078-4e7018379f7e?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80') center/cover;
  color: $white;
  padding: 4rem 2rem;
  text-align: center;
  margin-bottom: 4rem;
  
  h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    font-weight: 700;
  }
  
  p {
    font-size: 1.25rem;
    opacity: 0.9;
    max-width: 700px;
    margin: 0 auto;
  }
}

.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  background: #e0e0e0;
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.left-section, .right-section {
  flex: 1;
  background: #ffffff;
  padding: 30px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.left-section {
  max-width: 50%;
  background: #eef7ff;
}

.right-section {
  max-width: 50%;
  background: #eef7ff;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.contact-info h2 {
  font-size: 30px;
  margin-bottom: 15px;
  color: #333;
  text-align: center;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 20px;
  color: #555;
  margin-bottom: 20px;
}

.contact-item svg {
  color: #2196f3;
  font-size: 26px;
}

.company-details h3 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
  text-align: center;
}

.company-details p {
  font-size: 20px;
  color: #555;
  margin-bottom: 10px;
  text-align: center;
}

.form-container {
  max-width: 100%;
}

.form-container h2 {
  font-size: 36px;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-group {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
  color: #555;
}

.form-group input,
.form-group textarea {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px 130px 15px 130px;
  width: 100%;
  font-size: 16px;
  color: #333;
  background-color: #f9f9f9;
  transition: all 0.3s ease-in-out;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #2196f3;
  box-shadow: 0 0 5px rgba(33, 150, 243, 0.5);
}

textarea {
  height: 150px;
  resize: none;
}

.buttonSubmit {
  background: linear-gradient(135deg, #2196f3, #007bb5);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 15px 30px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
}

.buttonSubmit:hover {
  background: linear-gradient(135deg, #007bb5, #2196f3);
  transform: scale(1.05);
}



.social-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}

.social-icons .MuiIconButton-root {
  background-color: #fff;
  border-radius: 50%;
  padding: 10px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.social-icons .MuiIconButton-root:hover {
  transform: scale(1.3);
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.3);
}

.social-icons .MuiSvgIcon-root {
  font-size: 32px;
  transition: color 0.3s ease-in-out;
}

.social-icons .facebook-icon {
  color: #1877F2;
}

.social-icons .twitter-icon {
  color: #1DA1F2;
}

.social-icons .instagram-icon {
  color: #E4405F;
}

.social-icons .MuiIconButton-root:hover .facebook-icon {
  color: #0e5a99;
}

.social-icons .MuiIconButton-root:hover .twitter-icon {
  color: #0d81c7;
}

.social-icons .MuiIconButton-root:hover .instagram-icon {
  color: #c13584;
}

@media (max-width: 1024px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .left-section, .right-section {
    flex-basis: 100%;
    max-width: 100%;
    text-align: center;
  }

  .right-section {
    font-size: 18px;
  }

  .buttonSubmit {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 20px;
  }

  .left-section, .right-section {
    padding: 20px;
    text-align: left;
  }

  .form-group input,
  .form-group textarea {
    padding: 15px 130px 15px 130px;
  }
}

@media (max-width: 480px) {
  .header_section {
    font-size: 18px;
  }

  .form-container h2 {
    font-size: 28px;
  }

  .form-group label {
    font-size: 16px;
  }

  .form-group input,
  .form-group textarea {
    font-size: 14px;
    padding: 15px 130px 15px 130px;
  }

  .buttonSubmit {
    font-size: 16px;
    padding: 12px;
  }
  
}

