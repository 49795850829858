/* Reset default body styles */
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden; /* Prevent horizontal scroll */
  background-color: #0c0c0f; /* Match the background color of .slider-main-container */
}

.slider-main-container {
  background-color: #0c0c0f;
  color: white;
  padding: 1rem 0;
  min-height: 100vh; /* Ensure it covers the full viewport height */
  width: 100%; /* Ensure it covers the full width */
  overflow: hidden; /* Hide any overflowing content */
}

.slider-content {
  padding: 0.6rem;
  padding-bottom: 0.8rem;
  font-size: 1.4rem;
  text-align: center; /* Center-align text for better readability */
}

/* Media Queries for Responsiveness */

/* For tablets and small desktops (768px - 1200px) */
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .coverflow-container {
    margin-top: -3rem;
  }

  .slider-main-container {
    padding: 2rem 0; /* Increased padding for tablets */
  }

  .slider-content {
    font-size: 1.6rem; /* Slightly larger font size for tablets */
  }
}

/* For mobile devices (up to 767px) */
@media only screen and (max-width: 767px) {
  .slider-main-container {
    padding: 1.5rem 0; /* Adjusted padding for mobile */
  }

  .slider-content {
    margin-top: 4rem; /* Added margin for better spacing */
    font-size: 1.2rem; /* Smaller font size for mobile */
    padding: 0.5rem; /* Reduced padding for mobile */
  }

  .coverflow-container {
    margin-top: -2rem; /* Adjusted margin for mobile */
  }
}

/* For very small mobile devices (up to 480px) */
@media only screen and (max-width: 480px) {
  .slider-main-container {
    padding: 1rem 0; /* Further reduced padding for very small screens */
  }

  .slider-content {
    font-size: 1rem; /* Smaller font size for very small screens */
    padding: 0.4rem; /* Further reduced padding */
    margin-top: 3rem; /* Adjusted margin for very small screens */
  }

  .coverflow-container {
    margin-top: -1rem; /* Adjusted margin for very small screens */
  }
}