
$primary-color: #5a4fcf;
$secondary-color: #1f4b2e;
$light-bg: #f8f9fa;
$dark-text: #2d3748;
$light-text: #4a5568;
$white: #ffffff;
$box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);




.hero-content{
  background: linear-gradient(135deg, rgba($primary-color, 0.9), rgba($secondary-color, 0.9)),
              url('https://images.unsplash.com/photo-1579389083078-4e7018379f7e?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80') center/cover;
  color: $white;
  padding: 3rem 2rem;
  text-align: center;
  margin-top: -2rem;
  max-width: 2000px;
  margin-bottom: 2rem;
  margin-right: -1rem;
  margin-left: -1rem;
 
  .pricing-title {
    font-size: 2.5rem;
    font-weight: 800;
    margin-top: 1rem;
    line-height: 1.2;
  }

  .pricing-subtitle {
    font-size: 1.5rem;
    opacity: 0.9;
    font-weight: 300;
  }
}







.pricing-container {
  margin-top: 40px;
  text-align: center;
  padding: 3rem 1rem;
  background-color: #f7f9fc;
}





.toggle-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  gap: 1rem;
}

.toggle-btn {
  background: #ddd;
  color: #333;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.toggle-btn:hover {
  background: #ccc;
}

.toggle-btn.active {
  background: #020202;
  color: white;
}

.section-title {
  font-size: 2rem;
  color: #020202;
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.pricing-plans {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
}

.plan-card {
  background: #020202;
  padding: 2rem;
  border-radius: 10px;
  width: 300px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  border: 2px solid #ddd;
}

.plan-card:hover {
  transform: translateY(-10px);
  border-color: #777b82;
}

.plan-card h3 {
  font-size: 1.5rem;
  color: #e9e921;
  margin-bottom: 0.5rem;
}

.plan-card .price {
  font-size: 1.8rem;
  font-weight: bold;
  color: #ff8800;
  margin-bottom: 1rem;
}

.plan-card ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 1.5rem;
}

.plan-card ul li {
  font-size: 1rem;
  color: #F8F9FC;
  margin-bottom: 0.5rem;
}

.plan-card .btn {
  background-color: #ff8800;
  color: #fff;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: background 0.3s ease;
}

/* Highlight the popular plan */
.popular {
  background: #020202;
  color: #ff8800;
  border-color: #3b3c42;
}

.popular .price {
  color: #ff8800;
}

.popular ul li {
  color: white;
}

.popular .btn {
  background-color: #ff8800;
  color: #ddd;
}

/* Creator Plan - Different Styling */
.creator-plan {
  background: #020202;
  border-color: #ffb84d;
}

.creator-plan .price {
  color: #ff8800;
}

.creator-plan .btn {
  background-color: #ff8800;
  color: white;
}

.creator-plan .btn:hover {
  background-color: #cc6e00;
}

.highlight {
  color: #E9E921;
}

/* Enhanced Responsive Design */
@media (max-width: 1200px) {
  .plan-card {
    width: 280px;
  }
}

@media (max-width: 1024px) {
  .pricing-plans {
    justify-content: space-around;
  }
  
  .plan-card {
    width: 45%;
    max-width: 320px;
    padding: 1.5rem;
  }
  
  .pricing-title {
    font-size: 2.2rem;
    margin-top: 6rem;
  }
  
  .section-title {
    font-size: 1.8rem;
    margin-top: 2rem;
  }

  .toggle-buttons{
    margin-top: 5rem;

  }
  .plan-card{
    margin-bottom: 14rem;
  }
}

@media (max-width: 900px) {
  .pricing-container {
    padding: 2.5rem 1rem;
  }
  
  .toggle-buttons {
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .pricing-plans {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
  
  .plan-card {
    width: 80%;
    max-width: 400px;
    margin-bottom: 0;
  }
  
  .pricing-title {
    font-size: 2rem;
  }
  
  .pricing-subtitle {
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
  }
  
  .toggle-btn {
    padding: 0.6rem 1.2rem;
  }
  
  .section-title {
    font-size: 1.6rem;
    margin-top: 2rem;
  }
}

@media (max-width: 640px) {
  .plan-card {
    width: 90%;
    padding: 1.5rem 1rem;
  }
  
  .pricing-title {
    font-size: 1.8rem;
  }
  
  .toggle-buttons {
    gap: 0.5rem;
  }
  
  .toggle-btn {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .pricing-container {
    padding: 2rem 0.5rem;
    margin-top: 30px;
  }
  
  .pricing-title {
    font-size: 1.6rem;
  }
  
  .pricing-subtitle {
    font-size: 1rem;
    padding: 0 0.5rem;
  }
  
  .toggle-buttons {
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }
  
  .toggle-btn {
    width: 80%;
  }
  
  .section-title {
    font-size: 1.4rem;
    margin-top: 1.5rem;
  }
  
  .plan-card {
    width: 95%;
    padding: 1.2rem 0.8rem;
  }
  
  .plan-card h3 {
    font-size: 1.3rem;
  }
  
  .plan-card .price {
    font-size: 1.5rem;
  }
  
  .plan-card ul li {
    font-size: 0.9rem;
  }
  
  .plan-card .btn {
    padding: 0.6rem 1rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 360px) {
  .pricing-title {
    font-size: 1.5rem;
  }
  
  .plan-card {
    width: 100%;
  }
  
  .toggle-btn {
    width: 90%;
  }
}