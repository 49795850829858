.product-explore-main-container {
  padding: 2rem;
  padding-top: 0;
  padding-bottom: 4.4rem;
  height: auto;
  background: #05051E;
  color: #C2C7DE;
  margin-top: -1px;

  .product-explore-heading-section {
    text-align: center;

    button {
      border-radius: 0.4rem;
      background: linear-gradient(135deg, #7b527b63, #4f615d33);
      color: #b5bad2;
      padding: 1.5rem 3rem;
      font-size: 1.4rem;
      border: none;
      outline: none;
      cursor: pointer;
      text-transform: uppercase;
    }

    h1 {
      font-size: 3.2rem;
      padding-top: 2rem;
      background: linear-gradient(135deg, #955595, #399580);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-transform: capitalize;
    }

    h4 {
      padding-top: 1rem;
      padding-bottom: 0.6rem;
      margin: auto;
      width: 50%;
      line-height: 1.4rem;
      font-size: 1.3rem;
      font-weight: normal;
    }
  }

  .product-explore-content {
    border-radius: 2rem;
    padding: 4rem;
    border: 1px dotted #bdbdbd;
    background: #0A0B23;
    margin: 0 auto;
    margin-top: 2rem;

    .product-explore-content-heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 94%;
      margin: 0 auto;
      background: #212237;
      border-radius: 2rem;

      div {
        width: 100%;
        padding: 0.6rem;
        font-size: 1.5rem;
        font-weight: 700;
        display: grid;
        justify-content: space-around;
      }

      .one {
        color: #9e9ead;
        cursor: pointer;
      }

      .two {
        color: #9e9ead;
        cursor: pointer;
      }

      .three {
        color: #9e9ead;
        cursor: pointer;
      }

      .selected {
        background-color: #16162E;
        color: rgb(148 163 222);
        border: 1px solid rgb(148 163 222);
        border-radius: 2rem;
        cursor: pointer;
      }
    }

    .product-explore-content-options {
      position: relative;
      margin-top: 2rem;

      .product-explore-content-options-one {
        display: flex;
        justify-content: space-evenly;
        align-items: baseline;

        div {
          h3 {
            font-size: 1.6rem;
            margin-left: 1rem;
            margin-top: 1rem;
            color: #f9fafa;
            ;
          }

          span {
            font-size: 1.07rem;
            margin-left: 1rem;
            margin-right: 0.4rem;
            margin-top: 1rem;
            color: #C2C7DE;
          }

          button {
            font-size: 1.1rem;
            background: linear-gradient(135deg, #ba31ba, #399580);
            color: #c2c7de;
            border: none;
            outline: none;
            padding: 0.6rem 1rem;
            margin: 1.4rem 0;
            width: 12rem;
            border-radius: 0.2rem;
            cursor: pointer;
            z-index: 9;
          }

          p {
            font-size: 1.1rem;
          }
        }
      }

      .product-explore-content-options>div {
        position: absolute;
        display: flex;
        justify-content: space-between;
        width: 100%;
        top: 0;
        left: 0;
        border: 1px solid black;
      }

      .product-explore-content-options-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: baseline;
        width: 30%;
        min-height: 30rem;
        border: 1px solid gray;
        border-radius: 1rem;

        div {
          display: grid;
          position: relative;
          width: 100%;
        }

        img::before,
        div::after {
          content: '';
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          min-height: 100%;
          border-top-left-radius: 1rem;
          border-top-right-radius: 1rem;
          background: linear-gradient(135deg, #c065c063, #4f615d33);
        }

        .card-content {
          padding: 0rem;
          border-radius: 0;
          display: flex;
          flex-direction: column;
          justify-content: start;
          align-items: start;
          min-height: 16rem;

          button {
            font-size: 1.1rem;
            background: linear-gradient(135deg, #7b4397, #dc2430);
            color: #fff;
            border: none;
            padding: 0.75rem 1.5rem;
            margin: 1.4rem 0;
            margin-left: 1rem;
            border-radius: 0.5rem;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
            font-weight: bold;
            text-transform: uppercase;
          }
          
          button:hover {
            background: linear-gradient(135deg, #dc2430, #7b4397);
            transform: scale(1.05);
            box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
          }
          
        }

        .card-content::after {
          border-radius: 0;
          border-bottom-left-radius: 1rem;
          border-bottom-right-radius: 1rem;
        }

        img {
          width: 100%;
          height: 14rem;
          object-fit: cover;
          border-top-left-radius: 1rem;
          border-top-right-radius: 1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .product-explore-main-container .product-explore-content .product-explore-content-options .product-explore-content-options-one {
    flex-wrap: wrap;
    gap: 2rem;
  }

  .product-explore-main-container .product-explore-content .product-explore-content-options .product-explore-content-options-container {
    width: 48%;
  }

  .product-explore-main-container .product-explore-heading-section h4 {
    width: 90%;
    margin: 0 auto;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    line-height: 2rem;
    font-size: 1.7rem;
  }
}

@media screen and (max-width: 993px) {
  .product-explore-main-container {
    padding: 1rem 2rem;
  }

  .product-explore-main-container .product-explore-content .product-explore-content-options .product-explore-content-options-container {
    width: 80%;
  }

  .product-explore-main-container .product-explore-heading-section h1 {
    font-size: 2.8rem;
    padding-top: 1rem;
  }

  .product-explore-main-container .product-explore-content .product-explore-content-heading .selected,
  .product-explore-main-container .product-explore-content .product-explore-content-heading .two,
  .product-explore-main-container .product-explore-content .product-explore-content-heading .one,
  .product-explore-main-container .product-explore-content .product-explore-content-heading .three {
    font-size: 1rem;
  }

}

@media screen and (max-width: 660px) {
  .product-explore-main-container .product-explore-content .product-explore-content-options .product-explore-content-options-container {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 500px) {
  .product-explore-main-container .product-explore-heading-section {
    text-align: left;
  }

  .product-explore-main-container .product-explore-heading-section h1 {
    font-size: 2.4rem;
    text-align: left;
    padding: 0 24px;
    padding-top: 1.4rem;
  }

  .product-explore-main-container .product-explore-heading-section h4 {
    width: 90%;
    text-align: left;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
  }

  .product-explore-main-container .product-explore-heading-section button {
    font-size: 1.1rem;
    width: max-content;
    margin-left: 24px;
    float: left;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
}

@media only screen and (max-width: 560px) {
  .product-explore-main-container {
    padding: 0.5rem;

    .product-explore-heading-section {
      h1 {
        width: 80%;
        margin: 0 auto;
        text-align: center;
      }

      h4 {
        text-align: left;
      }
      
     
      
    }

    .product-explore-content {
      margin: 1rem;
      padding: 1rem;

      .product-explore-content-heading {
        flex-direction: column;

        div {
          font-size: 1.4rem;
          padding: 0.4rem;
        }
      }

      .product-explore-content-options {
        margin-top: 1.4rem;

        .product-explore-content-options-one div h3 {
          font-size: 1.6rem;
        }

        .product-explore-content-options-container {

          div {
            overflow-y: auto;
            height: auto;

            img {
              width: 100%;
              height: auto;
            }
          }

          div::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }
  }
}