/* Glow Border Animation */
.rp-form-container {
    width: 80%;
    max-width: 1400px;
    height: 80%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden; /* Ensure the glow effect doesn't overflow */
    z-index: 1; /* Ensure the container is above the glow */
    border-radius: 0.7rem; /* Match the border radius of the container */
    background: #0b1129; /* Background color for the container */
  }
  
  
    
  
    .glow {
        animation: rotate 4s linear infinite;
        -webkit-animation: rotate 4s linear infinite; /* For Safari and older browsers */
        filter: blur(20px);
        -webkit-filter: blur(20px); /* For Safari and older browsers */
      }
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  /* Rest of your existing CSS */
  :root {
    --teal: #03DAC6;
    --pink: #E94560;
    --violet: #BB86FC;
  
    --primary: #11998e;
    --secondary: #38ef7d;
    --white: #fff;
    --gray: #9b9b9b;
  }
  
  .rp-wrapper {
    background: #05051b;
    color: #ffffff;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 9;
  }
  
  .rp-form-container > .formleft-side {
    width: 50%;
    height: 34.4rem;
    background: #080737;
    color: #ffffff;
    padding: 0 2rem;
    background-image: url('https://img.freepik.com/free-vector/3d-growth-chart-diagram-with-shadow_1017-20818.jpg?t=st=1724062651~exp=1724066251~hmac=ee76c2b9e9e90e3d8c368b6f6aeb43e1ea7c3237338d6e04e813f0bb2ea7bba7&w=740'),
    linear-gradient(rgba(0, 0, 0), rgba(0, 0, 0));
    color: black;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 2rem;
    position: relative;
    box-shadow: 1px 0px 7px #eedfdf73;
    border-radius: 0.7rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .eye-icon {
    position: absolute;
    right: 30px;
    top: 30%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 0.8rem;
    color: #555;
}

.eye-icon:hover {
    color: #000;
}

.form__group {
    position: relative;
}

  
  .rp-form-container > .formleft-side::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(29, 71, 199, 0.88);
    color: #ffffff;
    z-index: 1;
    border-radius: 0.7rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .rp-sign-in-btn {
    color: #000;
    z-index: 9;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    padding-left: 1.6rem;
  }
  
  .rp-sign-in-btn > a {
    font-size: 1.2rem;
    font-weight: 700;
    background: transparent;
    color: #000000;
    padding: 0.6rem 2rem;
    padding-left: 0.6rem;
    text-decoration: none;
    text-transform: uppercase;
    background: linear-gradient(180deg, #361236, #092922);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
  }
  
  .rp-form-container > .formright-side {
    width: 50%;
    height: 34.4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
    background: #ffffff;
    box-shadow: 1px 0px 7px #d94747;
    border-radius: 0.7rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding-bottom: 1rem;
    z-index: 2; /* Ensure content is above the glow */
  }
  
  .rp-form-container > .formright-side > h2 {
    font-size: 1.9rem;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    margin-top: 4rem;
    background: linear-gradient(180deg, #2c122c, #399580);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
  }
  
  /* Form Styling */
  .rp-form-container > .formright-side > form,
  .rp-form-container > .formright-side > form > .input-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: baseline;
    gap: 0.4rem 1rem;
  }
  
  .rp-form-container > .formright-side > form {
    display: flex;
    align-items: center;
  }
  
  .rp-form-container > .formright-side > form > .input-container > input {
    width: 48%;
    padding: 1.2rem;
    border: none;
    outline: none;
    margin: 0.4rem 0;
    border-radius: 0.6rem;
  }
  
  .formright-side > form {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: baseline;
    width: 100%;
    height: 80%;
  }
  
  .rp-form-container > .formright-side > form > button {
    background: linear-gradient(135deg, #955595, #399580);
    color: white;
    border: none;
    outline: none;
    width: auto;
    padding: 0.6rem 2rem !important;
    font-size: 1.1rem !important;
    font-weight: 600 !important;
    border-radius: 0.4rem;
    margin-top: 2rem;
    text-transform: uppercase;
    justify-self: center;
  }
  
  .rp-form-container > .formright-side > form > button:hover {
    background: linear-gradient(135deg, var(--pink), var(--violet), var(--teal));
    box-shadow: 1px 1px 7px #a3a0a0;
    scale: 1;
    font-weight: bold;
  }
  
  /* Styling of Input Fields */
  .form__group {
    position: relative;
    padding: 15px 0 0;
    margin-top: 10px;
    width: 50%;
  }
  
  .form__field {
    width: 100%;
    border: 0;
    border-bottom: 2px solid var(--gray);
    outline: 0;
    font-size: 1.1rem;
    color: #141414;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
  }
  
  .form__field::placeholder {
    color: transparent;
  }
  
  .form__field:placeholder-shown ~ .form__label {
    font-size: 1.2rem;
    cursor: text;
    top: 20px;
  }
  
  .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: var(--gray);
  }
  
  .form__field:focus ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: var(--primary);
    font-weight: 700;
  }
  
  .form__field:focus {
    padding-bottom: 6px;
    font-weight: 500;
    border-width: 3px;
    border-image: linear-gradient(to right, var(--primary), var(--secondary));
    border-image-slice: 1;
  }
  
  .form__field:required,
  .form__field:invalid {
    box-shadow: none;
  }
  
  .error-message {
    color: red;
    font-size: 0.7rem;
    font-weight: 600;
  }
  
  .form-select {
    width: 100%;
    text-align: left;
    padding: 0.5rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  /* RESPONSIVE MEDIA QUERY */
  @media screen and (max-width: 1200px) {
    .rp-form-container > .formright-side > h2 {
      font-size: 2rem;
    }
  
    .rp-form-container > .formright-side > form {
      padding: 0 1rem;
    }
  
    .form__field:placeholder-shown ~ .form__label {
      font-size: 1rem;
    }
  
    .form__group {
      padding: 10px 0 0;
    }
  
    .rp-sign-in-btn {
      font-size: 1.2rem;
    }
  }
  
  @media screen and (max-width: 993px) {
    .rp-form-container {
      width: 100%;
      height: auto;
      flex-direction: column;
    }
  
    .rp-form-container > .formleft-side {
      width: 60%;
      height: auto;
      padding: 1.2rem;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  
    .rp-form-container > .formright-side {
      width: 60%;
      height: auto;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  
    .rp-form-container > .formright-side > h2 {
      font-size: 2rem;
    }
  
    .rp-form-container > .formright-side > form {
      padding: 0 1rem;
    }
  
    .form__field:placeholder-shown ~ .form__label {
      font-size: 1rem;
    }
  
    .form__group {
      padding: 10px 0 0;
    }
  
    .rp-sign-in-btn {
      font-size: 1.1rem;
    }
  
    .rp-form-container > .formright-side > form > button {
      padding: 0.88rem 2rem;
      margin-top: 1rem;
    }
  }
  
  @media screen and (max-width: 768px) {
    .rp-form-container {
      padding: 1rem 0.4rem;
    }
  
    .rp-form-container > .formleft-side {
      width: 80%;
      height: auto;
      padding: 1.2rem;
    }
  
    .rp-form-container > .formright-side {
      width: 80%;
      height: auto;
    }
  }
  
  @media screen and (max-width: 500px) {
    .rp-form-container > .formleft-side {
      width: 92%;
      height: auto;
      padding: 1.2rem;
    }
  
    .rp-form-container > .formright-side {
      width: 92%;
      height: auto;
    }
  }