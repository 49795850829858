.privacy-policy-container {
    background-color: #f7f7f7;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    color: #333;
  }
  
  .privacy-policy {
    max-width: 800px;
    margin: 0 auto;
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .privacy-policy h2 {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #2563eb;
    margin-bottom: 1.5rem;
  }
  
  .privacy-policy h3 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #1d4ed8;
    margin-top: 1.5rem;
  }
  
  .privacy-policy p,
  .privacy-policy ul {
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: #4b5563;
    margin-bottom: 1rem;
  }
  
  .privacy-policy ul {
    list-style-type: disc;
    padding-left: 1.5rem;
  }
  
  .privacy-policy .button-container {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
  
  .privacy-button {
    background-color: #2563eb;
    color: #fff;
    font-weight: bold;
    padding: 0.75rem 2rem;
    border-radius: 9999px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s;
  }
  
  .privacy-button:hover {
    background-color: #1e40af;
  }
  