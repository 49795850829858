.features-parent {
    width: 100%;
    background: #05051E;
    color: #C2C7DE;
    padding-top: 2rem;
    padding-bottom: 3rem;
    margin-top: -1px;

    h3 {
        text-align: center;
        font-size: 3.2rem;

        background: linear-gradient(135deg, #de1bde, #2d7a68);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;

        text-transform: capitalize;
    }

    p {
        text-align: center;
        width: 50%;
        margin: 0 auto;
        padding-top: 2.2rem;
        padding-bottom: 2.2rem;
        font-size: 1.3rem;
    }
}

.feature-main-container {
    background: #05051E;
    color: #C2C7DE;
    width: 100%;
    height: auto;
    padding: 2rem 5.4rem;
    display: flex;
    justify-content: space-between;
    align-items: start;

    .feature-cardWrapper {
        gap: 2rem;
        width: 45%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: baseline;

        .feature-card {
            height: auto;
            background: #0A0B23;
            box-shadow: 1px 1px 10px #484635;
            border: 1px solid #232323;
            border-radius: 1rem;
            padding: 0.6rem;
            display: flex;
            justify-content: space-around;
            align-items: baseline;

            .feature-cardContent {
                width: 90%;
                height: auto;
                padding: 1.42rem;

                .feature-top {
                    color: #C2C7DE;
                    font-size: 1.6rem;
                    margin-bottom: 1rem;
                }

                .feature-content {
                    font-size: 1.1rem;
                    text-transform: capitalize;
                    letter-spacing: 0.06rem;
                    color: #A3A8BF;
                }

                .cta-button {
                    background: linear-gradient(135deg, #955595, #399580);
                    border: none;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #A3A8BF;
                    padding: 0.6rem 1.4rem;
                    border-radius: 0.6rem;
                    margin-top: 1.2rem;
                    font-size: 1rem;
                    font-weight: 700;
                    text-transform: capitalize;
                    transition: all 0.3s ease-in-out;
                    box-shadow: 0 4px 10px rgba(149, 85, 149, 0.2);
                    position: relative;
                    overflow: hidden;
                  }
                  
                  /* Add a subtle gradient animation on hover */
                  .cta-button:hover {
                    background: linear-gradient(135deg, #399580, #955595);
                    color: #fff;
                    transform: scale(1.05);
                    box-shadow: 0 6px 15px rgba(149, 85, 149, 0.3);
                  }
                  
                  /* Create a glowing pulse effect */
                  @keyframes pulseGlow {
                    0% {
                      box-shadow: 0 0 10px rgba(149, 85, 149, 0.4);
                    }
                    50% {
                      box-shadow: 0 0 20px rgba(149, 85, 149, 0.6);
                    }
                    100% {
                      box-shadow: 0 0 10px rgba(149, 85, 149, 0.4);
                    }
                  }
                  
                  .cta-button:active {
                    animation: pulseGlow 0.6s;
                  }
                  
                  /* Add an SVG animation */
                  svg {
                    font-size: 2.2rem;
                    transition: transform 0.3s ease-in-out;
                  }
                  
                  .cta-button:hover svg {
                    transform: rotate(10deg);
                  }
            }

            svg {
                font-size: 2.2rem;
            }
        }
    }

    .feature-image-wrapper {
        width: 45%;
        margin-right: 2rem;

        img {
            width: 100%;
            height: 50rem;
            object-fit: cover;
            border-radius: 1rem;
        }
    }


}


@media screen and (max-width:1200px) {
    .features-parent p {
        width: 85%;
        margin: 0 auto;
        text-align: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
        line-height: 2rem;
        font-size: 1.7rem;
    }

    .feature-main-container {
        align-items: flex-start;
    }
}

@media screen and (max-width: 900px) {
    .features-parent {
        padding-bottom: 0;
    }

    .feature-main-container {
        flex-direction: column;
        gap: 1rem;
    }

    .feature-main-container .feature-cardWrapper {
        gap: 2rem;
        width: 80%;
        margin: 0 auto;
    }

    .feature-main-container .feature-image-wrapper {
        width: 80%;
        margin: 0 auto;
    }

    .features-parent p {
        width: 80%;
        margin: 0 auto;
    }

    .feature-main-container .feature-image-wrapper img {
        height: auto;
    }
}

@media screen and (max-width: 768px) {
    .features-parent {
        padding-top: 1rem;
        padding-bottom: 1rem;

        h3 {
            font-size: 2.8rem;
        }
    }

    .feature-main-container {
        gap: 2rem;
        padding: 2rem 3.4rem;
    }

    .feature-main-container .feature-cardWrapper {
        width: 100%;
    }

    .feature-main-container .feature-image-wrapper {
        width: 100%;
    }
}

@media screen and (max-width: 620px) {
    .feature-main-container {
        gap: 2rem;
        padding: 2rem 3rem;
    }
}

@media screen and (max-width: 560px) {
    .features-parent {
        .feature-main-container {
            padding: 2rem 2rem;
        }

        h3 {
            width: 90%;
            margin: 0 auto;
            text-align: left;
        }

        p {
            width: 90%;
            text-align: left;
        }
    }
}

@media screen and (max-width: 500px) {
    .feature-main-container {
        padding: 2rem 1rem;

        .feature-cardWrapper .feature-card .feature-cardContent {
            width: 100%;
            height: auto;
            padding: 1rem;
        }
    }

    .features-parent {
        padding-top: 1rem;
        padding-bottom: 0;

        p {
            width: 85%;
            margin: 0 auto;
            text-align: left;
            padding-top: 1.2rem;
            padding-bottom: 0.2rem;
        }
    }

    .features-parent h3 {
        font-size: 2.4rem;
        text-align: left;
        padding: 0 36px;
    }



    @media screen and (max-width: 768px) {
        .cta-button {
          font-size: 0.9rem;
          padding: 0.5rem 1rem;
        }
      
        svg {
          font-size: 1.8rem;
        }
      }
      
      @media screen and (max-width: 480px) {
        .cta-button {
          font-size: 0.85rem;
          padding: 0.4rem 0.8rem;
        }
      
        svg {
          font-size: 1.6rem;
        }
      }
}