///////////////////////////////////////////////////////
.main_section {
  padding: 2rem;
  padding-bottom: 4rem;
  background: #05051E;
  color: #C2C7DE;
}

.grid-item>.css-bhp9pd-MuiPaper-root-MuiCard-root {
  background-color: #23233d !important;
  color: #9594aa !important;
}

.testimonials-text {
  margin-left: 2rem;
  font-weight: 600;
  font-size: 3.2rem;
  background: linear-gradient(360deg, #b755b7, #399580);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  text-transform: capitalize;
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 3rem;
  gap: 30px;

  .btn {
    padding: 1rem 5rem;
    background: #212a37;
    color: #C2C7DE;
    font-size: 1.1rem;
  }

  .btn:hover {
    border-radius: 1.2rem;
    background: #212a37;
  }
}

.button-primary,
.button-secondary,
.button-tertiary {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  margin: 0.5rem;
}

.button-primary {
  background-color: #ebecf0;
  border: none;
  color: black;
  font-weight: bold;
}

.button-secondary {
  background-color: #ebecf0;
  border: none;
  color: black;
  font-weight: bold;
}

.button-tertiary {
  background-color: #ebecf0;
  border: none;
  color: black;
  font-weight: bold;
}

.grid-container {
  margin-top: 5rem;
}

.grid-container>.grid-childcontainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 94%;
  margin: 0 auto;
}

.grid-item {
  flex: 1 1 100%;
  max-width: 100%;
  padding: 1rem;
  cursor: pointer;
}

.smallContent {
  width: 200px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.testimonial-rightsideParent {
  display: flex !important;
  justify-content: center;
  align-items: end;
  height: 29rem;
  padding: 0 2rem;
  padding-bottom: 3rem;
  background: #05051e;
  color: #C2C7DE;
}

.right-side {
  padding: 1rem;
  box-sizing: border-box;
  font-size: 15px;
  padding: 35px;
  height: auto;
  box-shadow: 1px 1px 7px 0px gray;
  border-radius: 2rem;
  width: 70%;
  margin: 0 auto;
  gap: 1rem;
  color: #C2C7DE;
  position: relative;
  padding-top: 4.29rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.right-side>.testimonial-writerImage {
  position: absolute;
  top: -3rem;
  width: 120px;
  height: auto;
  border-radius: 50%;
  border-top: 2px solid #397895;
  box-shadow: 0px -4px 4px #399580;
  padding: 0.5rem;
}

.right-side>.testimonial-writerName {
  font-size: 2rem;
  text-transform: capitalize;
}

.right-side>.testimonial-writerProfile {
  font-size: 1.6rem;
  text-transform: uppercase;
}


.right-side>.testimonial-writerExperience {
  font-size: 1.2rem;
  text-align: center;
}

.right-side>.testimonial-starContainer {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0.4rem;
}

.right-side>.testimonial-starContainer>img {
  width: 2rem;
  height: auto;
}

.testimonial-container {
  margin: 0 auto;
  padding: 2rem;
  padding-top: 0;
  padding-bottom: 3.6rem;
  text-align: center;
  background: #05051E;
  color: #A3A8BF;
  margin-top: -1px;
}

.testimonial-container>.heading {
  color: #212943;
  font-size: 3.2rem;
  font-weight: 600;
  margin-bottom: 0.4rem;
  background: linear-gradient(135deg, #955595, #399580);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-transform: capitalize;
}

///////////////////////////////////////////////

.slick-dots li button:before {
  color: white !important;
}

@media screen and (max-width: 768px) {
  .grid-container>.grid-childcontainer {
    flex-direction: column;
    gap: 5rem;
  }

  .main-content {
    display: ruby;
  }

  .right-side {
    width: 100%;
  }

  .button-container {
    flex-wrap: nowrap;
  }
}

@media screen and (max-width: 500px) {
  .right-side>.testimonial-writerExperience {
    text-align: left;
  }

  .right-side>.testimonial-writerProfile {
    text-align: left;
  }

  .right-side>.testimonial-writerName {
    text-align: left;
  }

  .testimonial-rightsideParent {
    padding: 0 1rem;
    // padding-top: 40rem;
    padding-bottom: 3rem;
    min-height: 40rem;
  }
}