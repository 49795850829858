// Variables
$primary-color: #5a4fcf;
$secondary-color: #1f4b2e;
$light-bg: #f8f9fa;
$dark-text: #2d3748;
$light-text: #4a5568;
$white: #ffffff;
$box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);

// Base Styles
.about-page {
  font-family: 'Inter', sans-serif;
  color: $dark-text;
  line-height: 1.6;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1.5rem;
}

.section-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  position: relative;

  &.centered {
    text-align: center;
  }

  .highlight {
    color: $primary-color;
  }
}

// Hero Section
.about-hero {
  background: linear-gradient(135deg, rgba($primary-color, 0.9), rgba($secondary-color, 0.9)),
              url('https://images.unsplash.com/photo-1579389083078-4e7018379f7e?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80') center/cover;
  color: $white;
  padding: 3rem ;
  text-align: center;

  .hero-content {
    max-width: 2000px;
    margin: 0 auto;
  }

  h1 {
    font-size: 2.5rem;
    font-weight: 800;
    margin-bottom: 2rem;
    line-height: 1.2;
  }

  .hero-subtitle {
    font-size: 1.5rem;
    opacity: 0.9;
    font-weight: 300;
  }
}

// About Section
.about-section {
  padding: 6rem 0;
}

.about-container {
  display: flex;
  align-items: center;
  gap: 4rem;
}

.about-image-wrapper {
  flex: 1;
  position: relative;
  min-height: 500px;

  .about-main-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
    box-shadow: $box-shadow;
    position: relative;
    z-index: 2;
  }

  .image-decoration {
    position: absolute;
    width: 80%;
    height: 80%;
    background-color: rgba($secondary-color, 0.1);
    border-radius: 12px;
    bottom: -30px;
    right: -30px;
    z-index: 1;
  }
}

.about-content {
  flex: 1;
}

.about-text {
  p {
    font-size: 1.1rem;
    color: $light-text;
    margin-bottom: 1.5rem;
    line-height: 1.8;
  }
}

.cta-container {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;

  .cta-button {
    padding: 0.8rem 2rem;
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: none;
    border-radius: 8px;
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 10px 20px rgba($primary-color, 0.2);
    }
  }

  .secondary-button {
    border-color: $primary-color;
    color: $primary-color;

    &:hover {
      background-color: rgba($primary-color, 0.05);
    }
  }
}

// Value Props Section
.value-props {
  padding: 6rem 0;
  background-color: $light-bg;

  .props-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-top: 3rem;
  }

  .prop-card {
    background: $white;
    padding: 2.5rem 2rem;
    border-radius: 12px;
    text-align: center;
    box-shadow: $box-shadow;
    transition: transform 0.3s ease;

    &:hover {
      transform: translateY(-5px);
    }

    .prop-icon {
      font-size: 3rem;
      margin-bottom: 1.5rem;
    }

    h3 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
      color: $primary-color;
    }

    p {
      color: $light-text;
      line-height: 1.6;
    }
  }
}

// Vision Section
.vision-section {
  padding: 6rem 0;
  background-color: $white; // Changed from $light-bg to create visual contrast

  .vision-container {
    display: flex;
    align-items: center;
    gap: 4rem;
  }

  .vision-content {
    flex: 1;
  }

  .vision-text {
    p {
      font-size: 1.1rem;
      color: $light-text;
      margin-bottom: 1.5rem;
      line-height: 1.8;
    }
  }

  .vision-image-wrapper {
    flex: 1;
    position: relative;
    min-height: 400px;

    .vision-main-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 12px;
      box-shadow: $box-shadow;
      position: relative;
      z-index: 2;
    }

    .vision-decoration {
      position: absolute;
      width: 80%;
      height: 80%;
      background-color: rgba($primary-color, 0.1);
      border-radius: 12px;
      top: -30px;
      left: -30px;
      z-index: 1;
    }
  }
}

// Team Section
.team-section {
  padding: 6rem 0;
  text-align: center;
  background-color: $light-bg;

  .team-description {
    max-width: 700px;
    margin: 0 auto 3rem;
    font-size: 1.1rem;
    color: $light-text;
    line-height: 1.8;
  }

  .team-stats {
    display: flex;
    justify-content: center;
    gap: 3rem;
    flex-wrap: wrap;
  }

  .stat-item {
    padding: 2rem;
    min-width: 200px;

    .stat-number {
      font-size: 3rem;
      font-weight: 700;
      color: $primary-color;
      margin-bottom: 0.5rem;
    }

    .stat-label {
      font-size: 1.1rem;
      color: $light-text;
    }
  }
}

// CTA Section
.cta-section {
  padding: 5rem 0;
  background: linear-gradient(135deg, $primary-color, $secondary-color);
  color: $white;
  text-align: center;

  h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    font-weight: 700;
  }

  .cta-button {
    background-color: $white;
    color: $primary-color;
    font-weight: 600;

    &:hover {
      background-color: darken($white, 5%);
    }
  }
}

// Responsive Design
@media (max-width: 1024px) {
  .about-container,
  .vision-container {
    flex-direction: column;
  }

  .about-image-wrapper,
  .vision-image-wrapper {
    min-height: 400px;
    width: 100%;
  }

  .props-grid {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  .vision-container {
    flex-direction: column-reverse;
  }
}

@media (max-width: 768px) {
  .about-hero h1 {
    font-size: 2.5rem;
  }

  .section-title {
    font-size: 2rem;
  }

  .props-grid {
    grid-template-columns: 1fr !important;
  }

  .team-stats {
    flex-direction: column;
    gap: 1rem !important;
  }
}

@media (max-width: 480px) {
  .about-hero {
    padding: 5rem 0;

    h1 {
      font-size: 2rem;
    }
  }

  .about-image-wrapper,
  .vision-image-wrapper {
    min-height: 300px;
  }

  .cta-container {
    flex-direction: column;
  }
}