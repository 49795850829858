.coming-soon-container {
  background: #05051E;
  color: #C2C7DE;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  text-align: center;

  .coming-soon-content {
    max-width: 800px;
    padding: 2rem;
    background: #0A0B23;
    border-radius: 1rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(149, 85, 149, 0.2);

    h1 {
      font-size: 3.2rem;
      margin-bottom: 1.5rem;
      background: linear-gradient(135deg, #955595, #399580);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-transform: capitalize;
    }

    p {
      font-size: 1.2rem;
      color: #A3A8BF;
      margin-bottom: 2rem;
      line-height: 1.6;
    }

    .countdown {
      display: flex;
      justify-content: center;
      gap: 2rem;
      margin: 2rem 0;

      .countdown-item {
        background: rgba(149, 85, 149, 0.1);
        padding: 1rem;
        border-radius: 0.5rem;
        min-width: 100px;

        .number {
          font-size: 2rem;
          font-weight: bold;
          color: #C2C7DE;
        }

        .label {
          font-size: 0.9rem;
          color: #A3A8BF;
          text-transform: uppercase;
        }
      }
    }

    .notify-button {
      background: linear-gradient(135deg, #955595, #399580);
      color: white;
      border: none;
      padding: 1rem 2rem;
      font-size: 1.1rem;
      border-radius: 0.5rem;
      cursor: pointer;
      transition: transform 0.3s ease;
      text-transform: uppercase;
      font-weight: 600;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 15px rgba(149, 85, 149, 0.3);
      }
    }
  }
}

@media (max-width: 768px) {
  .coming-soon-container {
    .coming-soon-content {
      h1 {
        font-size: 2.5rem;
      }

      p {
        font-size: 1rem;
      }

      .countdown {
        flex-wrap: wrap;
        gap: 1rem;

        .countdown-item {
          min-width: 80px;
          padding: 0.8rem;

          .number {
            font-size: 1.5rem;
          }

          .label {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
} 